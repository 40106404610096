<template>
  <div>
    <div class="course-wrapper" @click="jumpDetail">
      <div class="course-cover">
        <el-image class="cover" :src="course.courseLogo||selectDefaultImg(course.id)" fit="cover"></el-image>
        <div class="status" v-if="course.courseType === '2'">直播</div>
      </div>
      <div class="course-info">
        <div class="info">
          <p class="title">{{ course.courseName }}</p>
          <div class="prize">
            <div>
              <span v-if="course.courseDiscount > 0">
                {{ '￥' + course.courseDiscount }}
              </span>
              <span v-else>免费</span>
            </div>
            <div class="study-num">
              <span>{{ course.countStudy }}人已学习</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    course: {
      type: Object,
      default: () => {
      }
    }
  },
  methods: {
    jumpDetail() {
      this.$router.push({
        path: '/signup',
        query: {
          id: this.course.id
        }
      })
    }
  }
}
</script>

<style scoped lang="scss">
@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.course-wrapper {
  background: #FFFFFF;
  border-radius: 6px;
  overflow: hidden;
  width: 306px;

  .course-cover {
    height: 180px;
    position: relative;

    .cover {
      @include center;
      width: 100%;
      height: 100%;
    }

    .status {
      @include center;
      position: absolute;
      right: 10px;
      top: 10px;
      padding: 6px 8px;
      border-radius: 4px;
      background: #1d70ea;
      color: #FFFFFF;
      font-size: 14px;
    }
  }

  .course-info {
    padding: 0 12px;

    .info {
      height: 78px;
      font-size: 16px;
      font-weight: 600;

      .title {
        width: 100%;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 16px;
        color: #000000;
        margin-top: 18px;
      }

      .prize {
        margin-top: 30px;
        color: #1162EA;
        display: flex;
        justify-content: space-between;
        align-items: center;
        font-weight: normal;
        font-size: 14px;

        .study-num {
          span {
            color: #909090;
            font-size: 14px;
          }
        }

        span {
          color: #1162EA;
          font-size: 16px;
          font-weight: 600;

          span {
            color: #999;
            text-decoration: line-through;
            font-size: 13px;
          }
        }
      }
    }
  }
}
</style>

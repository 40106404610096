<template>
  <div :class="{'hidden': hidden}" class="pagination-container">
    <el-pagination
        v-if="total>0"
        :background="background"
        :current-page.sync="currentPage"
        :page-size.sync="pageSize"
        :page-sizes="pageSizes"
        :layout="layout"
        :total="total"
        v-bind="$attrs"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
    />
  </div>
</template>

<script>
export default {
  props: {
    total: {
      type: Number,
      default: 0
    },
    page: {
      type: Number,
      default: 1
    },
    limit: {
      type: Number,
      default: 20
    },
    pageSizes: {
      type: Array,
      default: () => [10, 20, 30, 50]
    },
    layout: {
      type: String,
      // default: 'total, sizes, prev, pager, next, jumper',
      default: 'prev, pager, next'
    },
    background: {
      type: Boolean,
      default: true
    },
    autoScroll: {
      type: Boolean,
      default: true
    },
    hidden: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    currentPage: {
      get: function () {
        return this.page
      },
      set: function (value) {
        this.$emit('update:page', value)
      }
    },
    pageSize: {
      get: function () {
        return this.limit
      },
      set: function (value) {
        this.$emit('update:limit', value)
      }
    }
  },
  data() {
    return {};
  },
  created() {
  },
  methods: {
    // 初始页currentPage、初始每页数据数pagesize和数据data
    handleSizeChange: function (value) {
      this.$emit('pagination', {page: this.currentPage, size: value})
    },
    handleCurrentChange: function (value) {
      this.$emit('pagination', {page: value, size: this.pageSize})
    },
  },
  mounted() {
  },
}
</script>

<style lang="scss" scoped>
.pagination-container {
  //background: #fff;
  padding: 32px 16px;
}

.pagination-container.hidden {
  display: none;
}
</style>

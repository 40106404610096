<template>
  <div>
    <div class="category-list-wrapper">
      <category :category="category" :subordinate="subordinate"
                :firstId="firstId" :second-id="secondId"
                :state="isFree"
                @handleCategory="handleCategory"
                @handleSubordinate="handleSubordinate"
                @setStatus="setStatus"></category>
    </div>
    <div class="course-list-wrapper">
      <course-card class="course-list-detail" v-for="item in courseList" :course="item" :key="item.id"></course-card>
    </div>
    <div class="pagination-wrapper">
      <pagination :total="total"
                  :page.sync="listParams.page"
                  :limit.sync="listParams.limit"
                  @pagination="getCourseList"></pagination>
    </div>
  </div>
</template>

<script>
import category from '@/components/category/index.vue'
import {getCourseList, getCourseCategory} from "../../api/course/course";
import pagination from '@/components/Pagination/index.vue'
import courseCard from '@/components/courseCard/index.vue'

export default {
  components: {
    category,
    pagination,
    courseCard
  },
  data() {
    return {
      category: [],
      subordinate: [],
      listParams: {page: 1, limit: 12},
      isFree: '',
      courseList: [],
      total: 0,
      firstId: '',
      secondId: '',
    }
  },
  methods: {
    // 获取一级课程分类
    getCourseCategory() {
      getCourseCategory({
        categoryType: 1,
        isFront: 1,
        parentId: 0,
      }).then((res) => {
        const list = [{
          id: '',
          categoryName: '全部'
        }]
        this.category = list.concat(res.rows)
      })
    },
    // 获取下级课程分类
    handleCategory(id) {
      this.firstId = id
      getCourseCategory({
        categoryType: 1,
        isFront: 1,
        floor: 1,
        parentId: this.firstId,
      }).then((res) => {
        const list = [{
          id: '',
          categoryName: '全部'
        }]
        this.subordinate = list.concat(res.rows)
        this.secondId = ''
        this.listParams.page = 1
        this.getCourseList()
      })
    },
    // 选择二级分类
    handleSubordinate(id) {
      this.secondId = id
      this.listParams.page = 1
      this.getCourseList()
    },
    // 获取课程收费类型
    setStatus(status) {
      this.isFree = status
      this.listParams.page = 1
      this.getCourseList()
    },
    // 获取课程列表
    getCourseList() {
      getCourseList({
        pageNum: this.listParams.page,
        pageSize: this.listParams.limit,
        isFree: this.isFree,
        firstCategoryId: this.firstId,
        secondCategoryId: this.secondId,
        courseType:1
      }).then((res) => {
        this.courseList = res.rows
        this.total = res.total
      })
    },
  },
  mounted() {
    this.getCourseCategory()
    this.getCourseList()
  }
}
</script>

<style scoped lang="scss">
@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-list-wrapper {
  width: 100%;
  background: #ffffff;
  box-shadow: 0px 0px 23px 1px rgba(0, 0, 0, 0.1);
}

.course-list-wrapper {
  @include center;
  justify-content: flex-start;
  flex-wrap: wrap;
  max-width: 1366px;
  min-width: 1366px;
  margin: 40px auto 0;

  .course-list-detail {
    margin-bottom: 40px;
    margin-right: 46px;
    cursor: pointer;
    transition: all 0.2s linear;

    &:hover {
      transform: translateY(-6px);
      box-shadow: 0px 0px 23px 1px rgba(0, 0, 0, 0.1);
    }

    &:nth-of-type(4n) {
      margin-right: 0;
    }
  }
}

.pagination-wrapper {
  @include center;
  width: 100%;
}
</style>

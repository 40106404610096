<template>
  <div class="category-wrapper">
    <div class="category-list" v-if="category.length>0">
      <div class="item" :class="firstId===item.id?'active':''" v-for="(item,index) in category" :key="index"
           @click="handleCategory(item)">
        {{ item.categoryName }}
      </div>
    </div>
    <div class="category-list" v-if="subordinate.length>0&&firstId !== ''">
      <div class="item" :class="secondId===item.id?'active':''" v-for="(item,index) in subordinate" :key="index"
           @click="handleSubordinate(item)">
        {{ item.categoryName }}
      </div>
    </div>
    <div class="category-list">
      <div class="item" :class="state===item.value?'active':''" v-for="(item,index) in courseTypes" :key="index"
           @click="setStatus(item)">
        {{ item.title }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  components: {},
  props: {
    category: {
      type: Array,
      default: () => []
    },
    subordinate: {
      type: Array,
      default: () => []
    },
    firstId: {
      type: [String, Number],
      default: ''
    },
    secondId: {
      type: [String, Number],
      default: ''
    },
    state: {
      type: [String, Number],
      default: ''
    }
  },
  data() {
    return {
      courseTypes: [
        {
          title: "全部",
          value: '',
        },
        {
          title: "付费",
          value: 0,
        },
        {
          title: "免费",
          value: 1,
        },
      ],
    };
  },
  mounted() {
  },
  methods: {
    // 点击一级分类
    handleCategory(item) {
      this.$emit('handleCategory', item.id)
    },
    // 点击二极分类
    handleSubordinate(item) {
      // console.log(item.id)
      this.$emit('handleSubordinate', item.id)
    },
    // 选择类型
    setStatus(item) {
      this.$emit('setStatus', item.value)
    }
  }
};
</script>

<style scoped lang="scss">
@mixin center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.category-wrapper {
  max-width: 1366px;
  min-width: 1152px;
  margin: 0 auto;
  padding: 42px 10px 32px;

  .category-list {
    @include center;
    padding: 10px 0;
    margin-bottom: 8px;
    justify-content: flex-start;
    border-bottom: 1px solid #DBDBDB;
    flex-wrap: wrap;

    .item {
      padding: 0 8px;
      margin: 0 12px 8px 0;
      border-radius: 6px;
      color: #545c63;
      font-size: 16px;
      cursor: pointer;
      height: 32px;
      line-height: 32px;

      &.active {
        color: #ffffff;
        background: #000000;
      }
    }
  }
}
</style>
